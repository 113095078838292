import * as Yup from 'yup';
import { telephoneRegex } from '../../utils/validation-utils';

const getRequiredMessage = fieldLabel => `${fieldLabel} is required`;
const NO_SELECTION_MESSAGE = 'Please select an option';

export const validationSchema = () =>
  Yup.object({
    email: Yup.string()
      .email('Enter a valid email')
      .required(getRequiredMessage('Email address')),
    firstName: Yup.string().required(getRequiredMessage('First name')),
    lastName: Yup.string().required(getRequiredMessage('Last name')),
    phoneNumber: Yup.string()
      .required(getRequiredMessage('Phone number'))
      .matches(telephoneRegex, 'Please enter a valid phone number'),
    profession: Yup.number().required(NO_SELECTION_MESSAGE),
    specialty: Yup.number().required(NO_SELECTION_MESSAGE),
    howDidYouFindUs: Yup.string().required(NO_SELECTION_MESSAGE),
    referrer: Yup.string().when('howDidYouFindUs', {
      is: 'Referred by someone',
      then: Yup.string().required(getRequiredMessage('Referrer name')),
    }),
  });
