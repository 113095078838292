import React from 'react';
import styles from './subtext-section.module.scss';
import { Typography } from '@ingenovis/iui';
import { Link } from 'gatsby';

const SubtextSection = () => {
  return (
    <div className={`is-hidden-mobile ${styles.subtextContainer}`}>
      <div className={styles.subtextItem}>
        <Typography
          letterSpacing="0.025rem"
          className={styles.textBlock}
          variant="meta-m"
        >
          Trustaff © 2022 All Rights Reserved
        </Typography>
      </div>
      <div className={styles.subtextItem}>
        <Link to="/terms-of-use/">
          <Typography
            letterSpacing="0.025rem"
            className={styles.textBlock}
            variant="meta-m"
          >
            Terms of Use
          </Typography>
        </Link>
      </div>
      <div className={styles.subtextItem}>
        <Link to="/privacy-policy/">
          <Typography
            letterSpacing="0.025rem"
            className={styles.textBlock}
            variant="meta-m"
          >
            Privacy Policy
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default SubtextSection;
