import React from 'react';
import { PhoneIcon, Typography } from '@ingenovis/iui';
import { Link } from 'gatsby';
import styles from './header.module.scss';

const Header = ({ Logo }) => {
  return (
    <header className={styles.header}>
      <Link to="/">
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.companyInfo}>
        <div className={styles.phoneNumber}>
          <PhoneIcon className={styles.phoneIcon} />
          <Typography variant="meta-l-alt">877-880-0346</Typography>
        </div>
        <span className={styles.divider}>|</span>
        <Typography variant="meta-m" className={styles.days}>
          Monday – Friday, 8am to&nbsp;6pm
        </Typography>
      </div>
    </header>
  );
};
export default Header;
