import apiService from './api-service';
import referrerDataService from './referrer-data-service';
import { useAppContext } from '../providers/app-provider';
import { useAuthenticatedRequest } from '../hooks/api-hooks';
import { useGaHelper } from './google-analytics-service';

export function useJobApplicationsApi() {
  const { applicantInfo } = useAppContext();
  const { post } = useAuthenticatedRequest();
  const { trackApplication } = useGaHelper();

  return {
    createApplication: function (
      formData,
      performPreliminaryDuplicateCheck,
      job,
      formLocation
    ) {
      let path = `/api/job-applications?checkIsLikelyDuplicate=${performPreliminaryDuplicateCheck}`;

      let applicationData = {
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        phoneNumber: formData.phoneNumber,
        emailAddress: formData.email,
        professionId: job
          ? null
          : formData.otherProfession || formData.profession,
        specialtyId: job ? null : formData.specialty,
        jobId: job ? job.id : null,
        howDidYouHearAboutUs: formData.howDidYouFindUs
          ? formData.howDidYouFindUs +
            (formData.howDidYouFindUs === 'Other' ? ' - ' + formData.other : '')
          : null,
        referringPersonName: formData.referrer || null,
        referringUrl: referrerDataService.getSavedReferrer() || null,
        utmCode: referrerDataService.getSavedUtmData() || null,
        browserUserAgent: navigator.userAgent,
      };

      const isQuickApply = false;
      trackApplication(
        applicationData.email,
        isQuickApply,
        applicationData.jobId,
        formLocation
      );
      return apiService.post(path, applicationData);
    },

    createQuickApplyApplication: function (profileId, jobId) {
      const formattedData = {
        referringUrl: referrerDataService.getSavedReferrer() || null,
        utmCode: referrerDataService.getSavedUtmData() || null,
        browserUserAgent: navigator.userAgent,
        jobId: jobId,
        profileId: profileId,
      };

      const isQuickApply = true;
      trackApplication(applicantInfo.email, isQuickApply, formattedData.jobId);

      return post('/api/one-click-applications', formattedData);
    },
  };
}
