import React from 'react';
import { Typography } from '@ingenovis/iui';
import HeroImage from '../../components/hero-image/hero-image';
import styles from './hero.module.scss';

const Hero = ({ imgFluid }) => (
  <HeroImage
    fluid={imgFluid}
    imgWrapperClass={styles.heroImageWrapper}
    heroOverlayClass={styles.heroOverlay}
  >
    <div className={styles.textWrapper}>
      <Typography color="#FFFFFF" variant="h3">
        Start your traveler journey today
      </Typography>
      <Typography color="#FFFFFF" variant="body-m" className={styles.subtitle}>
        Sign up, search jobs, and speak to a recruiter with absolutely no
        obligation.
      </Typography>
      <div className={styles.divider}></div>
    </div>
  </HeroImage>
);

export default Hero;
