import React from 'react';
import { useField } from 'formik';
import { Dropdown } from '@ingenovis/iui';
import PropTypes from 'prop-types';

const IuiFormikDropdown = ({ label, name, options, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = e => {
    helpers.setValue(e.target.value);
  };

  return (
    <Dropdown
      formHelperText={meta.error}
      id={name}
      label={label}
      name={name}
      error={meta.touched && meta.error}
      options={options}
      selectProps={{ onChange: handleChange, value: field.value }}
      {...props}
    />
  );
};

IuiFormikDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default IuiFormikDropdown;
