const leadSources = [
  'Referred by someone',
  'Call from recruiter',
  'Text from recruiter',
  'Email from recruiter',
  'Currently work for Trustaff',
  'Formerly worked for Trustaff',
  'Allied Travel Careers',
  'Allied Travel Web',
  'AlliedVIP',
  'Blue Pipes',
  'Careerbuilder',
  'Facebook',
  'Glassdoor',
  'Gypsy Nurse',
  'Healthcare Employment Network',
  'HealthJobsNationwide.com',
  'Highway Hypodermics',
  'Hired Nurses',
  'Indeed',
  'Instagram',
  'Internet search (Google, Yahoo, Bing)',
  'Jobs for Nurses',
  'Jobs2Careers',
  'LinkedIn',
  'Nurse-Recruiter.com',
  'NurseFly',
  'RN Travel Web',
  'RN VIP',
  'Talroo.com',
  'The Travelers Conference',
  'Travel Nurse Source',
  'TravelNursing.org',
  'Twitter',
  'Unemployment site',
  'Wanderly',
  'Youtube',
  'ZipRecruiter',
  'Other',
];

export default leadSources;
