import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { errorMessages } from '../../constants/error-messages';
import styles from './index.module.scss';

/* eslint-disable react-hooks/exhaustive-deps */

const Modal = ({
  onConfirm,
  onCancel,
  isOpen,
  title,
  message,
  confirmButtonText,
  disableConfirmButton,
  confirmButtonDisabledMessage,
  messageModifierClass,
  messageTextModifierClass,
  confirmButtonModifierClass,
  isCentered,
  isSubmitting,
  showCancelButton,
  cancelButtonText,
  showCancelIconButton,
  hasApiError,
  apiErrorMessage,
  showModalCloseButton,
  shouldSubmitFormOnConfirm,
  alternateActionButtons,
  addDividingLine,
  actionButtonsBodyClass,
  cancelButtonClass,
}) => {
  const ESC_KEY_CODE = 27;

  const handleKeyup = e => {
    if (e.keyCode === ESC_KEY_CODE) {
      onCancel();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup, false);
    return () => {
      window.removeEventListener('keyup', handleKeyup, false);
    };
  }, []);

  return (
    <div className={`modal ${isOpen ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className={`message ${messageModifierClass}`}>
          <div
            className={`message-header ${
              isCentered ? styles.centeredHeader : ''
            }`}
          >
            <h2>{title}</h2>
            {showCancelIconButton && (
              <button
                className="delete"
                aria-label="delete"
                type="button"
                onClick={() => onCancel()}
              ></button>
            )}
          </div>
          <div className={`message-body`}>
            <div
              className={`content ${
                isCentered ? 'has-text-centered' : ''
              } ${messageTextModifierClass}`}
            >
              {message}
            </div>
            {hasApiError && (
              <p className="content has-text-danger">{apiErrorMessage}</p>
            )}
            <div
              className={`${styles.actionButtons} ${
                isCentered ? styles.centered : ''
              } ${actionButtonsBodyClass}`}
            >
              <button
                className={`button ${confirmButtonModifierClass} ${
                  isSubmitting ? 'is-loading' : ''
                }`}
                onClick={() => {
                  onConfirm?.();
                }}
                disabled={disableConfirmButton}
                title={disableConfirmButton ? confirmButtonDisabledMessage : ''}
                type={shouldSubmitFormOnConfirm ? 'submit' : 'button'}
              >
                {confirmButtonText}
              </button>
              {alternateActionButtons}
              {showCancelButton && (
                <button
                  className={`button ${cancelButtonClass} `}
                  type="button"
                  onClick={() => onCancel()}
                >
                  {cancelButtonText}
                </button>
              )}
            </div>
          </div>
          {addDividingLine && <hr className={`${styles.dividingLine}`} />}
        </div>
      </div>
      {showModalCloseButton && (
        <button
          className="modal-close is-large"
          aria-label="close"
          type="button"
          onClick={() => onCancel()}
        ></button>
      )}
    </div>
  );
};

Modal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  confirmButtonText: PropTypes.string,
  messageModifierClass: PropTypes.string,
  messageTextModifierClass: PropTypes.string,
  confirmButtonModifierClass: PropTypes.string,
  isCentered: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  showCancelIconButton: PropTypes.bool,
  hasApiError: PropTypes.bool,
  apiErrorMessage: PropTypes.string,
  showModalCloseButton: PropTypes.bool,
  shouldSubmitFormOnConfirm: PropTypes.bool,
  disableConfirmButton: PropTypes.bool,
  confirmButtonDisabledMessage: PropTypes.string,
  alternateActionButtons: PropTypes.node,
  addDividingLine: PropTypes.bool,
  actionButtonsBodyClass: PropTypes.string,
  cancelButtonClass: PropTypes.string,
};

Modal.defaultProps = {
  onConfirm: () => {},
  confirmButtonText: 'Confirm',
  isCentered: false,
  messageModifierClass: '',
  confirmButtonModifierClass: '',
  showCancelButton: true,
  cancelButtonText: 'Cancel',
  showCancelIconButton: true,
  hasApiError: false,
  apiErrorMessage: errorMessages.REQUEST_FAILED_LONG,
  showModalCloseButton: true,
  shouldSubmitFormOnConfirm: false,
  disableConfirmButton: false,
  confirmButtonDisabledMessage: '',
  alternateActionButtons: [],
};

export default Modal;
