import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { CreateTheme, IuiThemeProvider, Linker } from '@ingenovis/iui';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';

import ApplicationForm from '../../components/apply/application-form';
import Header from '../../components/apply/header';
import Hero from '../../components/apply/hero';
import Footer from '../../components/apply/footer';
import SubtextSection from '../../components/apply/subtext-section';

export default ({ data }) => {
  const muiTheme = createTheme();
  const themeOpts = {
    brand: 'trustaff',
  };
  const theme = { ...muiTheme, ...CreateTheme(themeOpts) };
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 769px)`,
    },
  ];

  return (
    <>
      <Linker CustomLink={Link}>
        <Helmet>
          <title>Apply - Trustaff</title>
          <meta charSet="utf-8" />
          <meta
            name="Description"
            content="Complete the form below to get the ball rolling! A career specialist will be in touch shortly to answer any questions you have and begin your job search."
          />
          <meta property="og:title" content="Get started today" />
          <meta
            property="og:description"
            content="Complete the form below to get the ball rolling! A career specialist will be in touch shortly to answer any questions you have and begin your job search."
          />
          <meta
            property="og:image"
            content={`https://www.trustaff.com${data.desktopImage.childImageSharp.fluid.src}`}
          />
          <meta property="og:url" content="https://www.trustaff.com/apply/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Trustaff" />
          <meta property="og:type" content="website" />
          <meta name="twitter:site" content="@Trustaff" />
          <meta name="twitter:card" content="summary_large_image" />

          <link
            href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <MuiThemeProvider theme={theme}>
          <IuiThemeProvider theme={theme}>
            <Header Logo={theme.logos.default} />
            <Hero imgFluid={sources} />
            <ApplicationForm />
            <SubtextSection />
            <Footer sealImgFluid={data.sealImage.childImageSharp.fluid} />
          </IuiThemeProvider>
        </MuiThemeProvider>
      </Linker>
    </>
  );
};

export const pageQuery = graphql`
  {
    desktopImage: file(relativePath: { eq: "apply/field-with-trees.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileImage: file(
      relativePath: { eq: "apply/field-with-trees-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sealImage: file(
      relativePath: { eq: "apply/joint_commission_seal_of_approval_logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
