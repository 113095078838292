import React from 'react';
import { useField } from 'formik';
import { TextInput } from '@ingenovis/iui';
import PropTypes from 'prop-types';

const IuiFormikTextInput = ({ label, name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <TextInput
      error={meta.touched && Boolean(meta.error)}
      errorMessage={meta.touched && meta.error}
      id={name}
      name={name}
      label={label}
      {...field}
      {...props}
    />
  );
};

IuiFormikTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default IuiFormikTextInput;
