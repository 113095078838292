import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, Toast, Typography } from '@ingenovis/iui';
import { Link } from 'gatsby';

import apiService from '../../services/api-service';
import { getAllSpecialties } from '../../services/specialties-service';
import IuiFormikDropdown from '../iui/iui-formik-dropdown';
import IuiFormikTextInput from '../iui/iui-formik-text-input';
import LoadingSpinner from '../loading-spinner';
import styles from './application-form-content.module.scss';
import leadSources from '../../constants/lead-sources';
import { useAuth } from '../../providers/auth-provider';

const ApplicationFormContent = () => {
  const GREY_COLOR_CODE = '#707070';
  const [professionOptions, setProfessionOptions] = useState([]);
  const [allSpecialties, setAllSpecialties] = useState([]);
  const [filteredSpecialties, setFilteredSpecialties] = useState([]);
  const { getLoginPathBasedOnTalentStackActiveFlag } = useAuth();
  const { errors, isSubmitting, setFieldValue, status, submitCount, values } =
    useFormikContext();

  const formatDropdownOptions = items => {
    return items.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  };

  const getFormattedLeadSourceOptions = () => {
    return leadSources.map(source => {
      return { label: source, value: source };
    });
  };

  const hasValidationError = () => {
    return errors && Object.keys(errors).length > 0 && submitCount !== 0;
  };

  useEffect(() => {
    apiService.get('/api/professions').then(({ data }) => {
      setProfessionOptions(formatDropdownOptions(data));
    });

    getAllSpecialties().then(({ data }) => {
      setAllSpecialties(data);
    });
  }, []);

  useEffect(() => {
    setFieldValue('specialty', '');

    setFilteredSpecialties(
      [...allSpecialties].filter(
        specialty => specialty.professionId === values.profession
      )
    );
  }, [values.profession]);

  return (
    <>
      <div className={styles.input}>
        <IuiFormikTextInput label="Email address" name="email" />
      </div>
      <div className={styles.input}>
        <IuiFormikTextInput label="First name" name="firstName" />
      </div>
      <div className={styles.input}>
        <IuiFormikTextInput label="Last name" name="lastName" />
      </div>
      <div className={styles.input}>
        <IuiFormikTextInput
          label="Mobile phone number"
          name="phoneNumber"
          isPhoneInput
        />
      </div>
      <div className={`${styles.input} ${styles.dropdown}`}>
        <IuiFormikDropdown
          label="Profession"
          name="profession"
          options={professionOptions}
        />
      </div>
      {values.profession && (
        <div className={`${styles.input} ${styles.dropdown}`}>
          <IuiFormikDropdown
            label="Primary specialty"
            name="specialty"
            options={formatDropdownOptions(filteredSpecialties)}
          />
        </div>
      )}
      <div className={`${styles.input} ${styles.dropdown}`}>
        <IuiFormikDropdown
          label="How did you find us?"
          name="howDidYouFindUs"
          options={getFormattedLeadSourceOptions()}
        />
      </div>
      {values.howDidYouFindUs === 'Referred by someone' && (
        <div className={styles.input}>
          <IuiFormikTextInput label="Referrer" name="referrer" />
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <LoadingSpinner
          isResponsive
          isLoading={isSubmitting}
          classes={styles.buttonSpinner}
          size="2x"
        />
        <Button
          fullWidthMobile
          label="Continue"
          type="submit"
          disabled={isSubmitting}
          className={isSubmitting ? styles.buttonSubmitting : ''}
        />
      </div>

      <Toast
        variant="error"
        message="Oops! Please complete all fields before continuing."
        show={hasValidationError()}
        autoClose={false}
        className={styles.toast}
      />
      <Toast
        variant="error"
        message="Something went wrong. Please try again."
        show={(!hasValidationError() && status && status.apiError) ?? false}
        autoClose={false}
        className={styles.toast}
      />
      <div className={styles.subtextArea}>
        <Typography variant="body-s" textAlign="center" color={GREY_COLOR_CODE}>
          By creating an account, you agree to the{' '}
          <Link to="/privacy-policy/">
            <Typography
              color={GREY_COLOR_CODE}
              variant="body-s-link"
              textAlign="center"
            >
              Trustaff Privacy Policy and CCPA Privacy Notice for Job
              Applications
            </Typography>
          </Link>
        </Typography>
      </div>
      <div className={styles.subtextArea}>
        <Typography variant="meta-l-alt" textAlign="center">
          Already have an account?{' '}
          <Link to={getLoginPathBasedOnTalentStackActiveFlag()}>
            Sign&nbsp;in
          </Link>
        </Typography>
      </div>
      <div className={styles.subtextArea}>
        <Typography variant="meta-l-alt" textAlign="center">
          Are you a hospital looking for staffing?{'  '}
          <Link to="/staffing-solutions/">Click here</Link>
        </Typography>
      </div>
    </>
  );
};

export default ApplicationFormContent;
