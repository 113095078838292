import React from 'react';
import { useFormikContext } from 'formik';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Modal from '../modal';
import { useAuth } from '../../providers/auth-provider';

const PossibleDuplicateModal = ({
  possibleDuplicateEmail,
  setPerformPreliminaryDuplicateCheck,
  setShowConfirmationModal,
  showConfirmationModal,
}) => {
  const { getLoginPathBasedOnTalentStackActiveFlag } = useAuth();
  const { isSubmitting, submitForm } = useFormikContext();

  return (
    <Modal
      title={`Confirm your account`}
      isOpen={showConfirmationModal}
      message={`It looks like someone has already created an account using ${possibleDuplicateEmail}.  If this is you, please sign in instead.`}
      isCentered={true}
      confirmButtonText="Log in"
      confirmButtonModifierClass={`is-info`}
      messageModifierClass={`is-info`}
      showCancelButton={true}
      cancelButtonText={`Submit application`}
      showCancelIconButton={false}
      onConfirm={() => {
        navigate(getLoginPathBasedOnTalentStackActiveFlag());
      }}
      onCancel={() => {
        setPerformPreliminaryDuplicateCheck(false);
        setShowConfirmationModal(false);
        submitForm();
      }}
      isSubmitting={isSubmitting}
      showModalCloseButton={false}
    />
  );
};

PossibleDuplicateModal.propTypes = {
  possibleDuplicateEmail: PropTypes.string,
  setPerformPreliminaryDuplicateCheck: PropTypes.func.isRequired,
  setShowConfirmationModal: PropTypes.func.isRequired,
  showConfirmationModal: PropTypes.bool.isRequired,
};

PossibleDuplicateModal.defaultProps = {
  possibleDuplicateEmail: '',
};

export default PossibleDuplicateModal;
