import React from 'react';
import { Typography } from '@ingenovis/iui';
import styles from './footer.module.scss';

const Footer = () => {
  return (
    <div className={`${styles.footer}`}>
      <Typography variant="body-m" textAlign="center" color="#363636">
        <span className={`${styles.coloredText}`}>4000+ travelers </span>
        currently supporting communities in need
      </Typography>
    </div>
  );
};

export default Footer;
