import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { navigate } from 'gatsby';

import ApplicationFormContent from './application-form-content';
import { errorMessages } from '../../constants/error-messages';
import formLocations from '../job-application-form/form-locations';
import { httpResponses } from '../../constants/http-responses';
import PossibleDuplicateModal from './possible-duplicate-modal';
import { urlParams } from '../../constants/url-params';
import { useAppContext } from '../../providers/app-provider';
import { useGaHelper } from '../../services/google-analytics-service';
import { useJobApplicationsApi } from '../../services/job-applications-service';
import { validationSchema } from './validation-schema';
import styles from './application-form.module.scss';
import { useAuth } from '../../providers/auth-provider';

const ApplicationForm = () => {
  const { createApplication } = useJobApplicationsApi();
  const {
    trackDupeApplicationAttempt,
    triggerApplicationTrackingEvent,
    trackSourceReferred,
    trackReferralEntered,
  } = useGaHelper();
  const [
    performPreliminaryDuplicateCheck,
    setPerformPreliminaryDuplicateCheck,
  ] = useState(true);
  const [possibleDuplicateEmail, setPossibleDuplicateEmail] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { setApplicantInfo } = useAppContext();
  const { getLoginPathBasedOnTalentStackActiveFlag } = useAuth();

  return (
    <div className={`${styles.container}`}>
      <div className={styles.wrapper}>
        <Formik
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            profession: '',
            specialty: '',
            howDidYouFindUs: '',
            referrer: '',
          }}
          validationSchema={validationSchema()}
          onSubmit={(values, actions) => {
            actions.setStatus({
              apiError: false,
            });

            triggerApplicationTrackingEvent(null, formLocations.APPLY_PAGE);
            if (values.referrer) {
              trackSourceReferred('Apply', values.email);
              trackReferralEntered('Apply', values.email, values.referrer);
            }
            createApplication(
              values,
              performPreliminaryDuplicateCheck,
              null,
              formLocations.APPLY_PAGE
            )
              .then(({ data }) => {
                const { profileId, isExistingUser } = data;
                setApplicantInfo({
                  profileId: profileId,
                  email: values.email,
                });
                if (isExistingUser)
                  navigate(getLoginPathBasedOnTalentStackActiveFlag());
                else
                  navigate(
                    `/profile/expertise/?${urlParams.APPLICATION_SUCCESS}=true`
                  );
              })
              .catch(err => {
                actions.setSubmitting(false);

                if (
                  err?.response?.status === httpResponses.CONFLICT_ERROR_CODE
                ) {
                  setPossibleDuplicateEmail(
                    err.response.data.maskedDuplicateEmail
                  );
                  trackDupeApplicationAttempt(values.email);
                  setShowConfirmationModal(true);
                  return;
                }
                actions.setStatus({
                  apiError: errorMessages.UNABLE_TO_SAVE,
                });
              });
          }}
        >
          <>
            <Form className={styles.form}>
              <ApplicationFormContent />
            </Form>
            <PossibleDuplicateModal
              possibleDuplicateEmail={possibleDuplicateEmail}
              setPerformPreliminaryDuplicateCheck={
                setPerformPreliminaryDuplicateCheck
              }
              setShowConfirmationModal={setShowConfirmationModal}
              showConfirmationModal={showConfirmationModal}
            />
          </>
        </Formik>
      </div>
    </div>
  );
};

export default ApplicationForm;
